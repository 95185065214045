<template>
     <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class="lg:mx-40 mx-7 rounded-2xl bg-blueNew1 lg:my-20 my-14 mt-10 lg:pb-4 pb-10">
     <div class="lg:w-auto bg-blueNew1 text-white lg:flex lg:justify-center lg:items-stretch lg:mt-0 w-80 min-h-[33rem] mt-12 mb-12 shadow-[0_2.5rem_2rem_#00000030] relative  rounded-3xl">
        <div class="lg:w-2/3 relative">
            <div class="lg:overflow-hidden lg:relative lg:flex lg:items-center h-full">
                <picture>
                    <source
                        srcset="../../assets/images/illustration-woman-online-desktop.svg"
                        media="(min-width: 1024px)"
                    >
                    <img class="lg:-left-[4.25rem] lg:top-0 lg:relative lg:z-10 lg:w-auto lg:max-w-full lg:drop-shadow-none w-60 absolute -top-[6.75rem] left-11 mx-auto drop-shadow-[0_2rem_0_#aaaaff30]" src="../../assets/images/illustration-woman-online-mobile.svg" alt="illustration">
                    
                </picture>
                
                <img class="hidden lg:block absolute -left-[17.9rem] top-2 scale-[2]" src="../../assets/images/bg-pattern-desktop.svg" alt="background pattern">
            </div>
        </div>

        <div class="lg:pr-16 lg:w-1/2">
            <h1 class="lg:mt-16 lg:text-left text-4xl font-bold text-dark-blue text-center mt-[8.5rem] mb-7">FAQ</h1>
            <DropdownItem>
                <template #header>Comment partager mes coordonées avec des téléphones sans NFC ? </template>
                Si vous souhaitez partager votre profil avec quelqu'un qui n'a pas de lecteur NFC sur son téléphone, vous pouvez simplement lui montrer le QR Code de votre carte et lui demander de le scanner avec l'appareil photo de son téléphone. C'est une méthode simple et pratique !
            </DropdownItem>
            <DropdownItem>
                <template #header>Faut-il une application pour utiliser ma carte Tag’n Go ?</template>
                Il n'est pas nécessaire d'utiliser une application pour utiliser votre carte ou pour que la personne qui reçoit vos informations puisse les consulter. En effet, votre profil s'ouvrira directement dans le navigateur du téléphone de la personne qui reçoit vos informations. De plus, vous pouvez accéder à votre Profil Digital depuis votre espace client sur notre site internet.
            </DropdownItem>
            <DropdownItem>
                <template #header>Faut-il payer un abonnement ? </template>
                Après votre commande, aucun frais d'abonnement ne vous seront demandés. Vous pourrez utiliser votre carte et modifier vos coordonnées de manière illimitée.
            </DropdownItem>
            <DropdownItem>
                <template #header>Combien de temps faudra-t-il pour recevoir ma carte Tag’n Go ? </template>
            
                Après avoir passé votre commande, votre carte vous sera livrée dans un délai de 48 heure ouvrable.
            </DropdownItem>
            <DropdownItem>
                <template #header>Vous n'avez pas reçu votre commande ? </template>
                Si vous n'avez pas reçu votre carte Tag’n Go dans les 48h jours ouvrés, veuillez nous contacter par e-mail à l'adresse contact@tagango.com ou par téléphone au 77 .
            </DropdownItem>
            <DropdownItem>
                <template #header>NFC : quel côté de la carte utiliser ? </template>
                Toutes nos cartes sont équipées de la technologie NFC et peuvent être utilisées des deux côtés, à la fois sur le recto et sur le verso.</DropdownItem>
        </div>
    </div>
  </div> 
</template>

<script>
import DropdownItem from '../DropdownItem.vue';

export default {
  name: "index",

  components: {
    DropdownItem

    },
}

</script>
